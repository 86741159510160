import React from 'react';
import cn from 'classnames';
import s from './Home.module.scss';
import Button from '../../components/Button';
import { useForm } from '../../components/Form';
import { useI18n } from '../../i18n';

export default function StepSection({
  index,
  total,
  onButtonClick,
  buttonType,
  children,
}) {
  const { isSubmitting } = useForm();
  const { t } = useI18n();
  return (
    <section className={cn(s.section, 'fullscreen')}>
      <div className={s.content}>
        <div className={s.step}>
          {index + 1}/{total}
        </div>
        <div>{children}</div>
        <div className={s.btnWrapper}>
          <Button
            type={buttonType}
            onClick={onButtonClick}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {index + 1 === total ? t('calculate_button') : t('continue_button')}
          </Button>
        </div>
      </div>
    </section>
  );
}
