import React, { useState, createContext, useContext, useCallback } from 'react';

const useFooterState = () => {
  const [hideFooter, setHideFooter] = useState(
    useFooterState.defaults.hideFooter
  );
  const toggleFooter = useCallback(
    (v) => setHideFooter(typeof v === 'boolean' ? v : (s) => !s),
    []
  );
  return { hideFooter, toggleFooter };
};
useFooterState.defaults = {
  hideFooter: false,
  toggleFooter: () => {},
};

export const AppContext = createContext({ ...useFooterState.defaults });

export const AppContextProvider = ({ children, ...value }) => {
  const footerState = useFooterState();
  return (
    <AppContext.Provider value={{ ...value, ...footerState }}>
      {children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
