export default {
  hero_title: 'Independence day',
  hero_subtitle:
    'Wann ist mein finanzieller Independence Day - wann könnte ich mein Hamsterrad verlassen? \n \n Berechne über unseren Kalkulator, ab wann Dein Geld für Dich arbeitet – und Du nicht mehr für Geld. Ab wann kannst Du neue Freiheiten entdecken?',
  start_button: 'Start',
  calculate_button: 'Berechnen',
  continue_button: 'Fortsetzen',
  cta_button: 'Wie funktioniert es?',
  first_step: {
    input_label: 'Dein Alter?',
    info: 'Wie alt bist Du, oder die Person für die Du die Berechnung machen willst',
  },
  second_step: {
    input_label: 'Freies monatliches Einkommen?',
    info: 'Wieviel könntest Du jetzt monatlich sparen bzw. investieren',
  },
  third_step: {
    input_label: 'Summe Deiner Ersparnisse?',
    info: 'Wie hoch sind Deine aktuellen Ersparnisse (Bargeld, Festgeld, Aktien, Fonds etc.)',
  },
  fourth_step: {
    input_label: 'Wert Deiner Immobilien',
    info: 'Wie hoch ist der Wert Deiner Immobilien (abzüglich allfälliger Hypotheken)',
  },
  fifth_step: {
    input_label: 'Wie hoch soll Dein monatliches passives Einkommen sein?',
    info: 'Wenn Du nicht mehr arbeiten würdest, wie viel € brauchst Du dann monatlich?',
  },
  results: {
    your: 'Your',
    independence_day: 'Financial Independence',
    your_independence_day: 'Deine finanzielle Freiheit beginnt in {value}',
    years_old: `Du wirst dann {value} alt sein`,
    independence_day_double_investments:
      'Würdest Du monatlich das Doppelte  ({money}) sparen, dann wäre es schon in {years_old} so weit',
    years_old_double_investments: `Du wärest dann {value} alt.`,
    age_too_old:
      'Leider geht es sich mit der Unabhängigkeit nicht vor Deinem 67. Geburtstag aus...',
  },
  quick_edit: {
    form_title: 'Quick Edit - play around!',
    am: 'Ich bin',
    years_old: 'Jahre alt',
    save: 'Ich spare',
    each_month: '€ monatlich',
    saved: '€ bis jetzt',
    total: 'Ich habe Ersparnisse',
    real_estate: '€ Immobilienwert',
    own_equity: 'Mein Immobilienbesitz',
    passive_income_goal: 'Ich brauche monatlich',
    monthly_passive: '€ passives Einkommen',
  },
  subscribe_section: {
    title:
      'Wir entwicklen dazu gerade tolle, neue Tools, die Dir am Weg zur finanziellen Unabhängigkeit helfen - gerne halten wir Dich am Laufenden wenn Du uns Deine Mailadresse mitteilst!',
    input_label: 'Email-Adressen eingeben',
    btn_label: 'Updates bekommen',
    successfully_subscribed: 'Erfolgreich angemeldet',
  },
  locale: {
    en: 'EN',
    de: 'DE',
    ru: 'RU',
    uk: 'UK',
  },
  cookie_declaration: {
    title: 'Cookie Hinweis',
  },
  footer: {
    copyright: 'Copyright © {year} Talo IT GmbH',
    imprint: 'Imprint',
  },
  errors: {
    fill_all_fields: 'Fill all fields',
  },
  metadata: {
    home: {
      title: `Independence Day – When are you financially free, let´s be inspired`,
      description:
        'Finanzielle Unabhängigkeit Rechner: Ab wann arbeitet Ihr Geld für Sie? Und Sie nicht mehr für Geld? Wann ist Ihr Financial Independence Day?',
    },
    results: {
      title: `Independence Day – Wann bin ich finanziell unabhängig? Lass Dich inspirieren...`,
      description:
        'Finanzielle Unabhängigkeit Rechner: Ab wann arbeitet Ihr Geld für Sie? Und Sie nicht mehr für Geld? Wann ist Ihr Financial Independence Day?',
    },
    imprint: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Finanzielle Unabhängigkeit Rechner: Ab wann arbeitet Ihr Geld für Sie? Und Sie nicht mehr für Geld? Wann ist Ihr Financial Independence Day?',
    },
    fire: {
      title: `Independence Day – Financial Independence Retire Early (FIRE)`,
      description:
        'Finanzielle Unabhängigkeit Rechner: Dein Plan zu Unabhängigkeit - planen Dein Leben, lass Dich nicht treiben!',
    },
    cookie_declaration: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Finanzielle Unabhängigkeit Rechner: Ab wann arbeitet Ihr Geld für Sie? Und Sie nicht mehr für Geld? Wann ist Ihr Financial Independence Day?',
    },
  },
};
