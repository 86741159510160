import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useI18n } from '../../i18n';
import paths from '../../router/paths';
import s from './Footer.module.scss';

const footerColorToClassNameMap = {
  primary: s.primaryFooter,
  white: s.whiteFooter,
  transparent: s.transparentFooter,
};

const Footer = ({ hideFooter, color = 'primary' }) => {
  const { t } = useI18n();

  return (
    <footer
      className={cn(
        s.footer,
        footerColorToClassNameMap[color],
        hideFooter && s.hideFooter
      )}
    >
      <ul>
        <li>{t('footer.copyright', { year: new Date().getFullYear() })}</li>
        <li>
          <Link to={paths.imprint}>{t('footer.imprint')}</Link>
        </li>
        <li>
          <Link to={paths.cookieDeclaration}>
            {t('cookie_declaration.title')}
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
