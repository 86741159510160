import { de, enGB, ru, uk } from 'date-fns/locale';
import format from 'date-fns/format';
import I18n from './i18n/I18n';

/* eslint-disable import/prefer-default-export */
export const formatCurrency = (() => {
  const locale = I18n.getLocale();
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  });
  return (number) => {
    const parts = formatter.formatToParts(number);
    const strArray = [];
    // filter out the decimal part
    parts.forEach((part) => {
      if (['decimal', 'fraction'].includes(part.type)) return;
      strArray.push(part.value);
    });
    return strArray.join('');
  };
})();

export const formatValue = (value) => {
  const locale = I18n.getLocale();
  const formattedValue = Intl.NumberFormat(locale).format(value);
  return formattedValue;
};

export const formatDate = (date, dateFormat) => {
  const locales = { en: enGB, de, uk, ru };
  let locale = I18n.getLocale();
  locale = locale.includes('-') ? locale.split('-')[0] : locale;
  return format(date, dateFormat, { locale: locales[locale] });
};
