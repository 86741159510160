import { useCallback } from 'react';
import { gql } from 'graphql-request';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import graphQLClient from '../graphql/graphql-client';
import { routePatterns } from '../router/paths';

const INDEPENDENCE_DAY_SUBMIT = gql`
  mutation independenceDaySubmit($_id: ID, $input: IndependenceDayInput!) {
    independenceDaySubmit(_id: $_id, input: $input) {
      _id
      years_old_double_investments
      years_old
      ID_in_years
      ID_in_years_double_investments
      form_values {
        age
        monthly_savings
        total_savings
        real_estate_equity
        passive_income_goal
      }
    }
  }
`;

const useIndependenceDaySubmit = (_id) => {
  const history = useHistory();

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (variables, form) => {
      form.setSubmitting(true);
      try {
        const data = await graphQLClient.request(INDEPENDENCE_DAY_SUBMIT, {
          _id,
          input: { ...variables },
        });
        const result = data?.independenceDaySubmit;
        if (!_id) {
          history.push(routePatterns.results.stringify({ id: result._id }));
        }
        return result;
      } catch (error) {
        const message = get(
          error,
          'response.errors[0].message',
          'Something went wrong'
        );
        toast.error(message);
      } finally {
        form.setSubmitting(false);
      }
    },
    [history, _id]
  );

  return handleSubmit;
};

export default useIndependenceDaySubmit;
