import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import { Form } from '../../components/Form';
import TextInput from '../../components/TextInput';
import useSubscribeToUpdates from '../../hooks/useSubscribeToUpdates';
import { useI18n } from '../../i18n';
import s from './Results.module.scss';

const SubscribeSection = () => {
  const { t } = useI18n();
  const { id: calculationId } = useParams();
  const onSubmit = useSubscribeToUpdates(calculationId);

  const initialValue = { email: '' };

  return (
    <div className={cn('card', s.subscribeSection)}>
      <h3>{t('subscribe_section.title')}</h3>
      <Form onSubmit={onSubmit} initialValues={initialValue}>
        <TextInput
          name="email"
          label={t('subscribe_section.input_label')}
          required
        />
        <div className={s.btnWrapper}>
          <Button type="submit">{t('subscribe_section.btn_label')}</Button>
        </div>
      </Form>
    </div>
  );
};
export default SubscribeSection;
