export default {
  hero_title: 'День Незалежності',
  hero_subtitle:
    'Коли у мене День фінансової незалежності? Використовуйте наш калькулятор, щоб підрахувати, коли Ваші гроші будуть працювати на Вас. Коли можете відкрити нові свободи?',
  start_button: 'Почати',
  calculate_button: 'Розрахувати',
  continue_button: 'Продовжити',
  cta_button: 'Як це працює?',
  first_step: {
    input_label: 'Виберіть вік',
    info: 'Скільки Вам років або особи, для якої Ви хочете зробити розрахунок?',
  },
  second_step: {
    input_label: 'Щомісячні заощадження',
    info: 'Скільки коштів Ви могли б заощадити або вкласти?',
  },
  third_step: {
    input_label: 'Сумма Ваших заощаджень',
    info: 'Яка сума Ваших поточних заощаджень (готівка, строкові депозити, акції, тощо)?',
  },
  fourth_step: {
    input_label: 'Вартість нерухомості',
    info: 'Яка вартість вашої нерухомості (за мінусом існуючої іпотеки)?',
  },
  fifth_step: {
    input_label: 'Сумма пасивного прибутку',
    info: 'Яку суму пасивного доходу ви хочете отримувати на місяць?',
  },
  results: {
    your: 'Ваш',
    independence_day: 'День Незалежності',
    your_independence_day: 'Ваш День Незалежності буде через {value}',
    years_old: `Вам буде {value}`,
    independence_day_double_investments:
      'Якби Ви заощаджували та інвестували щомісяця {money}, День Незалежності міг би бути через {years_old}',
    years_old_double_investments: `Вам буде {value}`,
    age_too_old: 'Вибачте, але це не спрацює для вас до 67 років...',
  },
  quick_edit: {
    form_title: 'Швидке редагування - спробуйте!',
    am: 'Мені',
    years_old: 'років',
    save: 'Я заощаджую',
    each_month: '€ кожного місяця',
    total: 'Загалом у мене є',
    saved: '€ збережень',
    own_equity: 'Вартість моєї нерухомості',
    real_estate: '€',
    passive_income_goal: 'Мені потрібно',
    monthly_passive: '€ пасивного прибутку',
  },
  subscribe_section: {
    title:
      'Ми зробимо безліч чудових інструментів, які допоможуть вам - підпишіться на оновлення і ми повідомимо Вас',
    input_label: 'Ваша електронна адреса',
    btn_label: 'Підписатись',
    successfully_subscribed: 'Успішно виконано',
  },
  locale: {
    en: 'EN',
    de: 'DE',
    ru: 'RU',
    uk: 'UK',
  },
  cookie_declaration: {
    title: 'Cookie Declaration',
  },
  footer: {
    copyright: 'Copyright © {year} Talo IT GmbH',
    imprint: 'Умови використання сайту',
  },
  errors: {
    fill_all_fields: 'Заповніть всі поля!',
  },
  metadata: {
    home: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    results: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    imprint: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    fire: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    cookie_declaration: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
  },
};
