import React from 'react';
import ReactLoading from 'react-loading';
import { primaryColor } from '../styles/variables.scss';

const Loading = ({ size }) => {
  return (
    <ReactLoading type="spin" color={primaryColor} height={size} width={size} />
  );
};

export default Loading;
