import React, { useRef, useCallback } from 'react';
import steps from './steps';
import Fullscreen from '../../components/Fullscreen';
import StepSection from './Home.StepSection';
import HeroSection from './Home.HeroSection';
import { Form } from '../../components/Form';
import useIndependenceDaySubmit from '../../hooks/useIndependenceDaySubmit';
import { useI18n } from '../../i18n';
import { useAppContext } from '../../contexts/contexts';
import analytics from '../../modules/analytics';

const useSubmitFormHandler = () => {
  const onSubmit = useIndependenceDaySubmit();

  const handleSubmit = useCallback(
    (...props) => {
      onSubmit(...props);
      analytics.sendEvent({
        ec: 'Independence Day',
        ea: 'Added the new calculations',
        el: 'New calculation',
      });
    },
    [onSubmit]
  );
  return handleSubmit;
};

const initialValues = {
  age: 40,
  monthly_savings: 1000,
  total_savings: 40000,
  real_estate_equity: 250000,
  passive_income_goal: 2500,
};
const Independence = () => {
  const sliderRef = useRef();
  const { t } = useI18n();
  const onSubmit = useSubmitFormHandler();
  const onClickNext = () => {
    sliderRef.current.fullpageApi.moveSectionDown();
  };

  const { toggleFooter } = useAppContext();
  const onSlideLeave = React.useCallback(
    (section, origin) => {
      if (origin.index === 0) {
        toggleFooter(false);
      }
      if (section.index === 0) {
        toggleFooter(true);
      }
      // handle show footer
    },
    [toggleFooter]
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <Fullscreen ref={sliderRef} onSlideLeave={onSlideLeave}>
        <HeroSection onBtnClick={onClickNext} />
        {steps.map(({ key, component: C }, currentIndex) => (
          <StepSection
            key={key}
            index={currentIndex}
            total={steps.length}
            onButtonClick={onClickNext}
            buttonType={currentIndex === steps.length - 1 ? 'submit' : 'button'}
          >
            <C {...{ t }} />
          </StepSection>
        ))}
      </Fullscreen>
    </Form>
  );
};

export default Independence;
