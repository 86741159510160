import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Home from '../pages/Home';
import Imprint from '../pages/Imprint/Imprint';
import Fire from '../pages/Fire/Fire';
import Results from '../pages/Results';
import paths from './paths';
import CookieAgreement from '../pages/CookieDeclaration/CookieDeclaration';

const appRoutes = [
  {
    path: paths.home,
    Component: Home,
    footerConfig: { color: 'transparent' },
    exact: true,
  },
  {
    path: paths.results,
    Component: Results,
    footerConfig: { color: 'white' },
    exact: true,
  },
  {
    path: paths.imprint,
    Component: Imprint,
    footerConfig: {},
    exact: true,
  },
  {
    path: paths.fire,
    Component: Fire,
    footerConfig: {},
    expect: true,
  },
  {
    path: paths.cookieDeclaration,
    Component: CookieAgreement,
    footerConfig: {},
    expect: true,
  },
];

export default function RootRouter() {
  return (
    <Router>
      <Switch>
        {appRoutes.map(({ path, Component: C, footerConfig, exact }) => (
          <Route exact={exact} path={path} key={path}>
            <Layout footerConfig={footerConfig}>
              <C />
            </Layout>
          </Route>
        ))}

        <Redirect to={paths.home} />
      </Switch>
    </Router>
  );
}
