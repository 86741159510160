/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import cn from 'classnames';
import formatDuration from 'date-fns/formatDuration';
import { Form, useForm } from '../../components/Form';
import s from './Results.module.scss';
import NumberInput from '../../components/NumberInput/NumberInput';
import { useI18n } from '../../i18n';
import Button from '../../components/Button';
import { formatCurrency } from '../../helpers';

const Fieldset = ({ t }) => {
  const { isSubmitting } = useForm();

  return (
    <>
      <NumberInput
        max={60}
        name="age"
        endText={t('quick_edit.years_old')}
        label={t('quick_edit.am')}
        required
      />
      <NumberInput
        max={10000}
        name="passive_income_goal"
        endText={t('quick_edit.monthly_passive')}
        label={t('quick_edit.passive_income_goal')}
        required
      />
      <NumberInput
        max={10000}
        name="monthly_savings"
        endText={t('quick_edit.each_month')}
        label={t('quick_edit.save')}
        required
      />
      <NumberInput
        max={500000}
        name="total_savings"
        endText={t('quick_edit.saved')}
        label={t('quick_edit.total')}
        required
      />
      <NumberInput
        max={5000000}
        name="real_estate_equity"
        endText={t('quick_edit.real_estate')}
        label={t('quick_edit.own_equity')}
        required
      />
      <div className={s.btnWrapper}>
        <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {t('calculate_button')}
        </Button>
      </div>
    </>
  );
};

const QuickEditForm = ({
  onSubmit,
  initialValues,
  independenceDayResult,
  locale,
  cardRef,
}) => {
  const { t } = useI18n();
  const {
    ID_in_years,
    years_old,
    ID_in_years_double_investments,
    years_old_double_investments,
  } = independenceDayResult;

  return (
    <div className={cn('card', s.formWrapper)} ref={cardRef}>
      <h2>{t('quick_edit.form_title')}</h2>
      <div className={s.resultText}>
        {independenceDayResult.years_old <= 67 ? (
          <>
            <i>
              {t('results.your_independence_day', {
                value: formatDuration({ years: ID_in_years }, { locale }),
              })}
            </i>
            <b>
              {t('results.years_old', {
                value: formatDuration({ years: years_old }, { locale }),
              })}
            </b>
            <br />
            <i>
              {t('results.independence_day_double_investments', {
                money: formatCurrency(initialValues.monthly_savings * 2),
                years_old: formatDuration(
                  { years: ID_in_years_double_investments },
                  { locale }
                ),
              })}
            </i>
            <b>
              {t('results.years_old_double_investments', {
                value: formatDuration(
                  { years: years_old_double_investments },
                  { locale }
                ),
              })}
            </b>
          </>
        ) : (
          <div>{t('results.age_too_old')}</div>
        )}
      </div>
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <Fieldset t={t} />
      </Form>
    </div>
  );
};

export default QuickEditForm;
