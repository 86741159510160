import React, { useCallback } from 'react';
import { useForm } from '../Form';
import s from './TextInput.module.scss';

const TextInput = ({ name, required, label, type = 'text' }) => {
  const { values, setFieldValue } = useForm();

  const handleChange = useCallback(
    (e) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue]
  );

  return (
    <div className={s.fieldWrapper}>
      <label htmlFor={name} id={`label-${name}`}>
        {label}
      </label>
      <input
        name={name}
        value={values[name] || ''}
        type={type}
        autoComplete="off"
        className={s.textInput}
        onChange={handleChange}
        id={name}
        required={required}
        maxLength="64"
      />
    </div>
  );
};

export default TextInput;
