import { useCallback } from 'react';
import { gql } from 'graphql-request';
import { toast } from 'react-toastify';
import graphQLClient from '../graphql/graphql-client';
import { useI18n } from '../i18n';

const SUBSCRIBE_TO_UPDATES = gql`
  mutation subscribeToUpdates($calculationId: ID!, $email: String!) {
    subscribeToUpdates(calculationId: $calculationId, email: $email)
  }
`;

const useSubscribeToUpdates = (calculationId) => {
  const { t } = useI18n();

  const handleSubscribe = useCallback(
    async ({ email }, form) => {
      try {
        await graphQLClient.request(SUBSCRIBE_TO_UPDATES, {
          calculationId,
          email,
        });
        toast.success(t('subscribe_section.successfully_subscribed'));
      } catch (error) {
        toast.error(error.message);
      } finally {
        form.setSubmitting(false);
      }
    },
    [calculationId, t]
  );

  return handleSubscribe;
};

export default useSubscribeToUpdates;
