import React from 'react';

const FormContext = React.createContext();

export const Form = ({ children, onSubmit, initialValues }) => {
  const [values, setFormValues] = React.useState(initialValues || {});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const valuesRef = React.useRef();
  valuesRef.current = values;

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      // const formData = new FormData(e.target);
      // const values = Object.fromEntries(formData);
      const res = onSubmit(valuesRef.current, { setSubmitting });
      if (res instanceof Promise) {
        setSubmitting(true);
        res.finally(() => setSubmitting(false));
      }
    },
    [onSubmit]
  );

  const setFieldValue = React.useCallback((name, value) => {
    setFormValues((v) => ({ ...v, [name]: value }));
  }, []);

  return (
    <FormContext.Provider
      value={{
        setFormValues,
        setFieldValue,
        values,
        initialValues,
        setSubmitting,
        isSubmitting,
      }}
    >
      <form onSubmit={handleSubmit}>{children}</form>
    </FormContext.Provider>
  );
};

export function useForm() {
  const form = React.useContext(FormContext);
  return form;
}
