export default {
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  cookiebotScriptUrl: process.env.REACT_APP_COOKIEBOT_SCRIPT_URL,
  cookiebotDomainGroupId: process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID,
  fullpageLicenseKey: process.env.REACT_APP_FULLPAGE_LICENSE_KEY,
  ga: process.env.REACT_APP_GA,
  fullPageDragAndMoveKey:
    process.env.REACT_APP_FULLPAGE_DRAG_AND_MOVE_LICENSE_KEY,
};
