import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import routePaths from '../../router/paths';
import s from './Navbar.module.scss';

const Navbar = ({ children }) => {
  return (
    <nav className={s.navbar}>
      <div>
        <Link to={routePaths.home}>
          <img src={logo} alt="logo" className={s.logo} />
        </Link>
      </div>
      <div className={s.navbarContent}>{children}</div>
    </nav>
  );
};

export default Navbar;
