import React, { useCallback, useEffect, useState } from 'react';
import Input from './Input';
import s from './RangeInput.module.scss';

export default function Range({
  units = '',
  name,
  label,
  min = 0,
  defaultValue,
  max = 1000000,
  step = 1,
  onGrabStart,
  onGrabEnd,
  value,
  onChange,
}) {
  const [stepValue, setStepValue] = useState(step);
  const [numberInputValue, setNumberInputValue] = useState(value);

  const handleChange = useCallback(
    (e) => {
      const v = Number(e.target.value);
      setNumberInputValue(v);
      onChange(v);
    },
    [onChange, setNumberInputValue]
  );

  useEffect(() => {
    if (!Number.isFinite(value)) {
      if (value < min) {
        onChange(min);
      } else if (value > max) {
        onChange(max);
      } else {
        onChange(defaultValue || min);
      }
    }
  }, [value, onChange, defaultValue, min, max]);

  const ref = React.useRef();
  useEffect(() => {
    const $rangeInput = ref.current;
    if (typeof onGrabStart === 'function') {
      $rangeInput.addEventListener('touchstart', onGrabStart);
      $rangeInput.addEventListener('mousedown', onGrabStart);
    }
    if (typeof onGrabEnd === 'function') {
      $rangeInput.addEventListener('touchend', onGrabEnd);
      $rangeInput.addEventListener('mouseup', onGrabEnd);
    }
    return () => {
      if (typeof onGrabStart === 'function') {
        $rangeInput.removeEventListener('touchstart', onGrabStart);
        $rangeInput.removeEventListener('mousedown', onGrabStart);
      }
      if (typeof onGrabEnd === 'function') {
        $rangeInput.removeEventListener('touchend', onGrabEnd);
        $rangeInput.removeEventListener('mouseup', onGrabEnd);
      }
    };
  }, [onGrabStart, onGrabEnd]);

  useEffect(() => {
    const stepForBigValues = 10000;
    if (value >= 100000 && stepValue !== stepForBigValues) {
      setStepValue(10000);
    }
    if (value < 100000 && stepValue !== step) {
      setStepValue(step);
    }
  }, [value, step, max, stepValue]);

  return (
    <div className={s.fieldWrapper}>
      <Input
        value={numberInputValue}
        setValue={setNumberInputValue}
        min={min}
        max={max}
        name={name}
        suffix={units}
        className={s.numberInput}
        withEditIcon
      />
      <input
        ref={ref}
        min={min}
        max={max}
        step={stepValue}
        value={value}
        name={name}
        type="range"
        className={s.slider}
        id={name}
        onChange={handleChange}
      />
      <label htmlFor={name} className={s.sliderLabel} id={`label-${name}`}>
        {label}
      </label>
    </div>
  );
}
