import de from './de';
import en from './en';
import ru from './ru';
import uk from './uk';
import I18n from './I18n';

I18n.setTranslations({
  de,
  en,
  ru,
  uk,
});

export { I18n };
export * from './i18n-react';
