/* eslint-disable react-hooks/exhaustive-deps */
// import clamp from 'lodash/clamp';
import React, { useCallback, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import clamp from 'lodash/clamp';
import cn from 'classnames';
import { isNil } from 'lodash';
import { config } from '../NumberInput/NumberInput';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { useForm } from '../Form';
import s from './RangeInput.module.scss';
import { useI18n } from '../../i18n';

const NumberInput = ({
  value,
  setValue,
  name,
  label,
  min,
  max = 5000000,
  className,
  required,
  withEditIcon = false,
  ...props
}) => {
  const form = useForm();
  const inputRef = useRef();
  const { i18n } = useI18n();
  const locale = i18n.getLocale().substring(0, 2);
  const [, forceRerender] = useState(false);
  const [showIcon, toggleIcon] = useState(withEditIcon);

  const handleChange = useCallback(
    (_values) => {
      let v;
      if (isNil(_values.floatValue)) {
        v = null;
      } else {
        v = _values.floatValue > max ? max : _values.floatValue;
      }
      forceRerender((b) => !b);
      setValue(v);
    },
    [max, setValue]
  );

  const handleFocus = useCallback(() => {
    inputRef.current.focus();
    if (withEditIcon) toggleIcon(false);
  }, [withEditIcon, setValue]);

  const handleBlur = useCallback(() => {
    inputRef.current.blur();
    const v = Number.isFinite(value) ? value : form.values[name];
    const clampedValue = clamp(v, min, max);

    setValue(clampedValue);
    form.setFieldValue(name, clampedValue);

    if (withEditIcon) toggleIcon(true);
  }, [min, max, setValue, form, name, value, withEditIcon]);

  const handleIconClick = useCallback(() => {
    setValue('');
    inputRef.current.focus();
  }, []);

  return (
    <div className={s.fieldWrapper}>
      <label htmlFor={name} id={`label-${name}`}>
        {label}
      </label>
      {showIcon ? (
        <PencilIcon
          onClick={handleIconClick}
          className={cn(s.pencilIcon, !showIcon ? s.hideIcon : {})}
        />
      ) : null}
      <NumberFormat
        getInputRef={(elm) => {
          inputRef.current = elm;
        }}
        max={max}
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...config[locale]}
        name={name}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onValueChange={handleChange}
        autoComplete="off"
        id={name}
        required={required}
        className={cn(s.numberInput, className)}
        type="text"
        {...props}
      />
    </div>
  );
};

export default NumberInput;
