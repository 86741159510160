import React from 'react';
import { useAppContext } from '../../contexts/contexts';
import Metadata from '../Metadata';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import Footer from './Footer';
import s from './Layout.module.scss';
import Navbar from './Navbar';
import ScrollToTop from '../ScrollToTop';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';

export default function Layout({ children, footerConfig }) {
  const { hideFooter } = useAppContext();
  useGoogleAnalytics();

  return (
    <>
      <Metadata />
      <ScrollToTop />
      <Navbar>
        <LanguageSelect />
      </Navbar>
      <div className={s.layout}>{children}</div>
      <Footer {...footerConfig} hideFooter={hideFooter} />
    </>
  );
}
