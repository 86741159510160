import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import { useLocation, useParams } from 'react-router-dom';
import de from '../i18n/de';
import en from '../i18n/en';
import ru from '../i18n/ru';
import uk from '../i18n/uk';
import paths, { routePatterns } from '../router/paths';
import { useI18n } from '../i18n';

function getMetadataByPages(calculationId) {
  return [
    {
      path: paths.home,
      key: 'home',
    },
    {
      path: routePatterns.results.stringify({ id: calculationId }),
      key: 'results',
    },
    {
      path: paths.imprint,
      key: 'imprint',
    },
    {
      path: paths.fire,
      key: 'fire',
    },
    {
      path: paths.cookieDeclaration,
      key: 'cookie_declaration',
    },
  ];
}

const Metadata = () => {
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const metadataByPages = useMemo(() => getMetadataByPages(id), [id]);
  const defaultSeo = { key: 'home' };

  const seo =
    metadataByPages.find(({ path }) => path === pathname) || defaultSeo;

  seo.url = 'https://independenceday.work/';

  return (
    <Helmet>
      <meta name="image" content={seo.image} />
      <title>{t(`metadata.${seo.key}.title`)}</title>
      <meta
        name="description"
        lang="en"
        content={get(en, `metadata.${seo.key}.description`)}
      />
      <meta
        name="description"
        lang="de"
        content={get(de, `metadata.${seo.key}.description`)}
      />
      <meta
        name="description"
        lang="ru"
        content={get(ru, `metadata.${seo.key}.description`)}
      />
      <meta
        name="description"
        lang="uk"
        content={get(uk, `metadata.${seo.key}.description`)}
      />

      <meta property="og:url" content={seo.url} />
      <meta
        property="og:title"
        content={get(en, `metadata.${seo.key}.title`)}
      />
      <meta
        property="og:description"
        content={get(en, `metadata.${seo.key}.description`)}
      />
      <meta property="og:image" content={seo.image} />
      <meta
        name="twitter:title"
        content={get(en, `metadata.${seo.key}.title`)}
      />
      <meta
        name="twitter:description"
        content={get(en, `metadata.${seo.key}.description`)}
      />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
};

export default Metadata;
