import ReactGA from 'react-ga';
import settings from '../constants/settings';

function init() {
  ReactGA.initialize(settings.ga, { debug: settings.isDevelopment });
}

function sendEvent({ ec, ea, el }) {
  ReactGA.event({ category: ec, action: ea, label: el });
}

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
