// import RangeInput from '../../components/RangeInput';
import RangeInput from '../../components/RangeInput';
import s from './Home.module.scss';

export default [
  {
    key: 1,
    component: ({ t }) => (
      <>
        <RangeInput
          min={1}
          max={60}
          step={1}
          name="age"
          label={t('first_step.input_label')}
          // onGrabStart={disableSwipe}
          // onGrabEnd={enableSwipe}
        />
        <i className={s.info}>{t('first_step.info')}</i>
      </>
    ),
  },
  {
    key: 2,
    component: ({ t }) => (
      <>
        <RangeInput
          min={100}
          max={10000}
          step={100}
          units="€"
          name="passive_income_goal"
          label={t('fifth_step.input_label')}
          // onGrabStart={disableSwipe}
          // onGrabEnd={enableSwipe}
        />
        <i className={s.info}>{t('fifth_step.info')}</i>
      </>
    ),
  },
  {
    key: 3,
    component: ({ t }) => (
      <>
        <RangeInput
          min={100}
          max={10000}
          step={100}
          units="€"
          name="monthly_savings"
          label={t('second_step.input_label')}
          // onGrabStart={disableSwipe}
          // onGrabEnd={enableSwipe}
        />
        <i className={s.info}>{t('second_step.info')}</i>
      </>
    ),
  },
  {
    key: 4,
    component: ({ t }) => (
      <>
        <RangeInput
          min={0}
          max={2000000}
          step={1000}
          units="€"
          name="total_savings"
          label={t('third_step.input_label')}
          // onGrabStart={disableSwipe}
          // onGrabEnd={enableSwipe}
        />
        <i className={s.info}>{t('third_step.info')}</i>
      </>
    ),
  },
  {
    key: 5,
    component: ({ t }) => (
      <>
        <RangeInput
          min={0}
          max={5000000}
          step={1000}
          units="€"
          name="real_estate_equity"
          label={t('fourth_step.input_label')}
          // onGrabStart={disableSwipe}
          // onGrabEnd={enableSwipe}
        />
        <i className={s.info}>{t('fourth_step.info')}</i>
      </>
    ),
  },
];
