import React from 'react';
import useScript from 'react-script-hook';
import settings from '../../constants/settings';
import { useI18n } from '../../i18n';

export default function CookieAgreement() {
  const { t } = useI18n();
  useScript({
    src: settings.cookiebotScriptUrl,
  });

  return (
    <div className="content-wrapper">
      <h1>{t('cookie_declaration.title')}</h1>
      <div id="CookieDeclaration" />
    </div>
  );
}
