import React, { useContext, useEffect } from 'react';
import I18n from './I18n';

const I18nContext = React.createContext({
  locale: I18n.locale,
  t: () => I18n.t,
  i18n: I18n,
});

export function I18nProvider({ children }) {
  const locale = useCurrentLocale();

  return (
    <I18nContext.Provider value={{ locale, t: I18n.t, i18n: I18n }}>
      {children}
    </I18nContext.Provider>
  );
}

export function useI18n() {
  return useContext(I18nContext);
}

export function useCurrentLocale() {
  const [locale, setLocale] = React.useState(I18n.locale);
  useEffect(() => {
    const unsubscribe = I18n.onLocaleChange((newLocale) => {
      setLocale(newLocale);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return locale;
}
