import React, { useCallback, useState } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { useI18n } from '../../i18n';
import s from './LanguageSelect.module.scss';

const LanguageSelect = () => {
  const { t, i18n } = useI18n();
  const locales = i18n.getLocales();
  const language = i18n.getLocale().substring(0, 2);
  const [visible, setVisible] = useState(false);

  const handleSelect = useCallback(
    ({ key }) => {
      i18n.setLocale(key);
      setVisible(false);
    },
    [i18n]
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          className={s.menu}
          onSelect={handleSelect}
          activeKey={language}
          multiple={false}
        >
          {locales.map((l) => (
            <MenuItem key={l} className={s.menuItem}>
              {t(`locale.${l}`)}
            </MenuItem>
          ))}
        </Menu>
      }
      animation="slide-up"
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
      placement="bottomLeft"
    >
      <div className={s.triggeredContent}>{t(`locale.${language}`)} ▾</div>
    </Dropdown>
  );
};

export default LanguageSelect;
