import React from 'react';
import NumberFormat from 'react-number-format';
import isNil from 'lodash/isNil';
import clamp from 'lodash/clamp';
import cn from 'classnames';
import { useForm } from '../Form';
import s from './NumberInput.module.scss';
import { useI18n } from '../../i18n';

export const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  ru: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  uk: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

const NumberInput = ({
  name,
  label,
  min,
  max = 5000000,
  endText,
  className,
  sideEffectOnChange,
  required,
  ...props
}) => {
  const form = useForm();
  const { i18n } = useI18n();
  const locale = i18n.getLocale().substring(0, 2);

  const handleChange = React.useCallback(
    (_values) => {
      let v;
      if (isNil(_values.floatValue)) {
        v = null;
      } else {
        v = clamp(_values.floatValue, min, max);
      }
      form.setFieldValue(name, v);
      if (typeof sideEffectOnChange === 'function') {
        sideEffectOnChange({ values: { ...form.values, [name]: v }, form });
      }
    },
    [form, name, sideEffectOnChange, min, max]
  );

  return (
    <div className={s.fieldWrapper}>
      <label htmlFor={name} id={`label-${name}`}>
        {label}
      </label>

      <NumberFormat
        // min={min}
        max={max}
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...config[locale]}
        name={name}
        value={form.values[name]}
        onValueChange={handleChange}
        autoComplete="off"
        id={name}
        required={required}
        className={cn(s.numberInput, className)}
        type="text"
        {...props}
      />
      {endText ? <div className={s.endText}>{endText}</div> : null}
    </div>
  );
};

export default NumberInput;
