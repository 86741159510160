import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { isDesktop } from 'react-device-detect';
import settings from '../constants/settings';

const pluginWrapper = () => {
  // eslint-disable-next-line global-require
  require('../assets/plugins/fullpage.dragAndMove.min');
};
export default React.forwardRef(function Fullscreen(
  { children, onSlideLeave },
  ref
) {
  return (
    <ReactFullpage
      ref={ref}
      pluginWrapper={pluginWrapper}
      licenseKey={settings.fullpageLicenseKey}
      dragAndMoveKey={settings.fullPageDragAndMoveKey}
      scrollingSpeed={1000}
      normalScrollElements="input[type=range]"
      onLeave={onSlideLeave}
      scrollOverflow={false}
      dragAndMove={isDesktop ? 'vertical' : undefined}
      scrollBar={isDesktop}
      // touchSensitivity={10}
      render={() => {
        return (
          <ReactFullpage.Wrapper>
            {React.Children.map(children, (child) => (
              <div className="section">{child}</div>
            ))}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
});
