/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const INDEPENDENCE_DAY_RESULT = gql`
  query independenceDayResult($_id: ID!) {
    independenceDayResult(_id: $_id) {
      _id
      years_old
      years_old_double_investments
      ID_in_years
      ID_in_years_double_investments
      form_values {
        age
        monthly_savings
        total_savings
        real_estate_equity
        passive_income_goal
      }
    }
  }
`;
