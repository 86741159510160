export default {
  hero_title: 'День Независимости',
  hero_subtitle:
    'Когда у меня День финансовой независимости? Используйте наш калькулятор, чтобы подсчитать, когда Ваши деньги будут работать на Вас',
  start_button: 'Начать',
  calculate_button: 'Рассчитать',
  continue_button: 'Далее',
  cta_button: 'Как это работает?',
  first_step: {
    input_label: 'Выберите возраст',
    info: 'Сколько Вам лет?',
  },
  second_step: {
    input_label: 'Ежемесячные сбережение',
    info: 'Сколько денег Вы могли бы сэкономить или вложить?',
  },
  third_step: {
    input_label: 'Сумма Ваших сбережений',
    info: 'Какая сумма Ваших текущих сбережений (наличные, срочные депозиты, акции и т.д.)?',
  },
  fourth_step: {
    input_label: 'Стоимость недвижимости',
    info: 'Какова стоимость Вашей недвижимости (за минусом существующей ипотеки)?',
  },
  fifth_step: {
    input_label: 'Цель пассивного дохода',
    info: 'Какую сумму пассивного дохода вы хотите получать в месяц?',
  },
  results: {
    your: 'Ваш',
    independence_day: 'День Независимости',
    your_independence_day: 'Ваш День Независимости будет через {value}',
    years_old: `Вам будет {value}`,
    independence_day_double_investments:
      'Если бы вы экономили и инвестировали ежемесячно {money} День Независимости был бы через {years_old}',
    years_old_double_investments: `Вам будет {value}`,
    age_too_old:
      'Извините, но это не сработает для вас, пока вам не исполнится 67 лет....',
  },
  quick_edit: {
    form_title: 'Быстрое редактирование - попробуйте!',
    am: 'Мне',
    years_old: 'лет',
    save: 'Я экономлю',
    each_month: '€ каждый месяц',
    total: 'В общем у меня есть',
    saved: '€ сбережений',
    real_estate: '€',
    own_equity: 'Стоимость моей недвижимости',
    passive_income_goal: 'Мне нужно',
    monthly_passive: '€ пассивного дохода',
  },
  subscribe_section: {
    title:
      'Мы сделаем множество замечательных инструментов, которые помогут вам - подпишись на обновления и мы сообщим Вам',
    input_label: 'Ваш электронный адрес',
    btn_label: 'Подписаться',
    successfully_subscribed: 'Успешно выполнено',
  },
  locale: {
    en: 'EN',
    de: 'DE',
    ru: 'RU',
    uk: 'UK',
  },
  cookie_declaration: {
    title: 'Cookie Declaration',
  },
  footer: {
    copyright: 'Copyright © {year} Talo IT GmbH',
    imprint: 'Условия использования сайта',
  },
  errors: {
    fill_all_fields: 'Fill all fields',
  },
  metadata: {
    home: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    results: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    imprint: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    fire: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    cookie_declaration: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
  },
};
