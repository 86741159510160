export default {
  hero_title: 'Independence day',
  hero_subtitle:
    'Know your individual Independence Day to get more confidence in your life',
  start_button: 'Start',
  calculate_button: 'Calculate',
  continue_button: 'Continue',
  cta_button: 'How does it work?',
  first_step: {
    input_label: 'How old are you?',
    info: 'Input your age',
  },
  second_step: {
    input_label: 'Monthly savings',
    info: 'How much of your monthly income do you put aside for savings?',
  },
  third_step: {
    input_label: 'Total savings',
    info: 'What are your total savings?',
  },
  fourth_step: {
    input_label: 'Real estate equity',
    info: 'What is the value of you real estate (minus existing mortgage)?',
  },
  fifth_step: {
    input_label: 'Passive income goal',
    info: 'How much passive income do you want to generate per month?',
  },
  results: {
    your: 'Your',
    independence_day: 'Independence day',
    your_independence_day: 'Your independence day will be in {value}',
    years_old: `You'll be {value} old`,
    independence_day_double_investments:
      'If you would save and invest monthly {money} it would be within {years_old}',
    years_old_double_investments: `You'll be {value} old`,
    age_too_old:
      'Sorry, but it won´t work for you before you´re 67 years old...',
  },
  quick_edit: {
    form_title: 'Quick Edit',
    am: 'I am',
    years_old: 'years old',
    save: 'I save',
    each_month: '€ each month',
    saved: '€ saved',
    total: 'I have a total of',
    real_estate: '€ in real estate',
    own_equity: 'I own equity of',
    passive_income_goal: 'My passive income goal is',
    monthly_passive: '€ monthly passives',
  },
  subscribe_section: {
    title:
      'We´ll develop a lot of great tools which help you - sign up and we´ll inform you',
    input_label: 'Enter your email',
    btn_label: 'Subscribe',
    successfully_subscribed: 'Successfully subscribed',
  },
  locale: {
    en: 'EN',
    de: 'DE',
    ru: 'RU',
    uk: 'UK',
  },
  cookie_declaration: {
    title: 'Cookie Declaration',
  },
  footer: {
    copyright: 'Copyright © {year} Talo IT GmbH',
    imprint: 'Imprint',
  },
  errors: {
    fill_all_fields: 'Fill all fields',
  },
  metadata: {
    home: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    results: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Results Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    imprint: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Imprint Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    fire: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Fire Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
    cookie_declaration: {
      title: `Independence Day – Learn when you'll be financially free`,
      description:
        'Cookie Financial Independence Calculator: When Does Your Money Start Working For You? And you no longer for money? When is your Financial Independence Day?',
    },
  },
};
