import EventEmitter from 'events';
import get from 'lodash/get';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';

// inspired by https://github.com/edouardpagnier/react-light-i18n
class I18n {
  eventTypes = {
    LOCALE_CHANGE: 'LOCALE_CHANGE',
  };

  ee = new EventEmitter();

  translations;

  locale;

  languageFallback;

  setTranslations = (translations, fallback = undefined) => {
    this.translations = translations;
    if (this.locale === undefined) {
      const tmpLocale = this.getLocale();
      this.locale = tmpLocale.includes('-')
        ? tmpLocale.split('-')[0]
        : tmpLocale;
    }
    this.languageFallback = fallback === undefined ? 'en' : fallback;
  };

  setLocale(locale) {
    this.locale = locale.includes('-') ? locale.split('-')[0] : locale;
    this.ee.emit(this.eventTypes.LOCALE_CHANGE, this.locale);
    localStorage.setItem('language', this.locale);
  }

  t = (key, values) => {
    if (this.translations !== undefined) {
      if (this.locale in this.translations) {
        templateSettings.interpolate = /{([\s\S]+?)}/g;
        const str = get(this.translations[this.locale], key);
        const completed = template(str);
        return completed(values) || `No ${this.locale} translation for ${key}`;
      }
      if (key in this.translations[this.languageFallback]) {
        return this.translations[this.languageFallback][key];
      }
      return `No ${this.locale} translation for ${key}`;
    }
    // eslint-disable-next-line no-console
    console.warn(
      'ERROR: No translation defined, consider calling setTranslations() first.'
    );
    return '';
  };

  getLocale() {
    let locale = localStorage.getItem('language');
    if (locale) return locale;

    if (navigator.languages && navigator.languages.length) {
      // eslint-disable-next-line prefer-destructuring
      locale = navigator.languages[0];
    } else if (navigator.userLanguage) {
      locale = navigator.userLanguage;
    } else {
      locale = navigator.language;
    }
    return locale;
  }

  getLocales() {
    return ['en', 'de', 'ru', 'uk'];
  }

  onLocaleChange(cb) {
    this.ee.on(this.eventTypes.LOCALE_CHANGE, cb);
    return () => this.ee.off(this.eventTypes.LOCALE_CHANGE, cb);
  }
}

export default new I18n();
