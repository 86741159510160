import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { toast } from 'react-toastify';
import LoadingOverlay from '../LoadingOverlay';

const Md2Jsx = ({ source }) => {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(source)
      .then((res) => res.text())
      .then((r) => setData(r))
      .catch((err) => toast.error(err.message));
  }, [source]);

  if (!data) {
    return <LoadingOverlay />;
  }

  return (
    <div className="content-wrapper">
      <Markdown>{data}</Markdown>
    </div>
  );
};

export default Md2Jsx;
