import React from 'react';
import { useForm } from '../Form';
import RangeInput from './RangeInput';

export default function RangeFormInput(props) {
  const { name } = props;
  const { setFieldValue, values, initialValues } = useForm();
  const onChange = React.useCallback(
    (value) => setFieldValue(name, value),
    [name, setFieldValue]
  );
  return (
    <RangeInput
      defaultValue={initialValues[name]}
      {...props}
      value={values[name]}
      onChange={onChange}
    />
  );
}
