import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css';
import useVH from 'react-viewport-height';
import CookieBot from 'react-cookiebot';
import { AppContextProvider } from './contexts/contexts';
import RootRouter from './router/RootRouter';
import settings from './constants/settings';
import './styles/global.scss';
import { I18nProvider } from './i18n';
import './styles/index.scss';
import startup from './startup/init-ga';

startup();

export default function App() {
  useVH();
  return (
    <AppContextProvider>
      <I18nProvider>
        <RootRouter />
        <ToastContainer />
        <CookieBot domainGroupId={settings.cookiebotDomainGroupId} />
      </I18nProvider>
    </AppContextProvider>
  );
}
