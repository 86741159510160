import UrlPattern from 'url-pattern';

const paths = {
  home: '/',
  results: '/r/:id',
  imprint: '/imprint',
  fire: '/fire',
  cookieDeclaration: '/cookie-declaration',
};

export const routePatterns = Object.entries(paths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {}
);

export default paths;
