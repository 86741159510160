import React from 'react';
import s from './Home.module.scss';
import Button from '../../components/Button';
import { useI18n } from '../../i18n';

export default function HeroSection({ onBtnClick }) {
  const { t } = useI18n();
  return (
    <section className={s.hero}>
      <div className={s.text}>
        <h1>{t('hero_title')}</h1>
        <i>{t('hero_subtitle')}</i>
      </div>
      <div className={s.btnWrapper}>
        <Button onClick={onBtnClick}>{t('start_button')}</Button>
      </div>
    </section>
  );
}
