/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import formatDuration from 'date-fns/formatDuration';
import { de, enGB, ru, uk } from 'date-fns/locale';
import { formatCurrency } from '../../helpers';
import routePaths from '../../router/paths';
import graphQLClient from '../../graphql/graphql-client';
import { INDEPENDENCE_DAY_RESULT } from '../../graphql/queries';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useI18n } from '../../i18n';
import s from './Results.module.scss';
import Button from '../../components/Button';
import QuickEditForm from './QuickEditForm';
import useIndependenceDaySubmit from '../../hooks/useIndependenceDaySubmit';
import SubscribeSection from './SubscribeSection';
import analytics from '../../modules/analytics';

async function getIndependenceDayResult(_id) {
  const res = await graphQLClient.request(INDEPENDENCE_DAY_RESULT, { _id });
  return res;
}

const useFormSubmitHandler = (id, setData, cardRef) => {
  const onSubmit = useIndependenceDaySubmit(id);
  const handleSubmit = useCallback(
    (...props) => {
      onSubmit(...props).then((result) => setData(result));
      analytics.sendEvent({
        ec: 'Independence Day',
        ea: 'Editing existing calculations',
        el: 'Existing calculation',
      });
      const coordinates = cardRef.current.getBoundingClientRect();
      window.scrollTo({
        top: coordinates.top + window.pageYOffset - 100,
        behavior: 'smooth',
      });
    },
    [cardRef, onSubmit, setData]
  );
  return handleSubmit;
};

export default function Results() {
  const { id } = useParams();
  const [data, setData] = useState();
  const cardRef = useRef();
  const onSubmit = useFormSubmitHandler(id, setData, cardRef);
  const { t, i18n } = useI18n();

  const i18nLocale = i18n.getLocale().substring(0, 2);
  const locales = { en: enGB, de, uk, ru };
  const locale = locales[i18nLocale];

  useEffect(() => {
    if (!data) {
      getIndependenceDayResult(id).then((res) => {
        setData(res.independenceDayResult);
      });
    }
  }, [id, data]);

  if (!data) {
    return <LoadingOverlay />;
  }

  const {
    ID_in_years,
    years_old,
    ID_in_years_double_investments,
    years_old_double_investments,
    form_values,
  } = data;

  return (
    <div className={s.resultsContainer}>
      <div className={s.headerText}>
        <i className="text-sm">{t('results.your')}</i>
        <b className="text-md">{t('results.independence_day')}</b>
      </div>

      <div className={s.independenceDayText}>
        {years_old <= 67 ? (
          <>
            <i className="text-md">
              {t('results.your_independence_day', {
                value: formatDuration({ years: ID_in_years }, { locale }),
              })}
            </i>
            <b className="text-sm">
              {t('results.years_old', {
                value: formatDuration({ years: years_old }, { locale }),
              })}
            </b>
            <br />
            <i className="text-sm">
              {t('results.independence_day_double_investments', {
                money: formatCurrency(form_values.monthly_savings * 2),
                years_old: formatDuration(
                  { years: ID_in_years_double_investments },
                  { locale }
                ),
              })}
            </i>
            <b className="text-sm">
              {t('results.years_old_double_investments', {
                value: formatDuration(
                  { years: years_old_double_investments },
                  { locale }
                ),
              })}
            </b>
          </>
        ) : (
          <div className="text-sm">{t('results.age_too_old')}</div>
        )}
      </div>

      <Link to={routePaths.fire}>
        <Button variant="outline">{t('cta_button')}</Button>
      </Link>

      <div className={s.cardsWrapper}>
        <QuickEditForm
          onSubmit={onSubmit}
          independenceDayResult={data}
          initialValues={form_values}
          locale={locale}
          cardRef={cardRef}
        />

        <SubscribeSection />
      </div>
    </div>
  );
}
