import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../modules/analytics';

export default function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);
}
