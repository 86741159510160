import cn from 'classnames';
import s from './Button.module.scss';
import Loading from './Loading';

export const variantToClassNameMap = {
  primary: s.primary,
  outline: s.outline,
};

export default function Button({
  onClick,
  type = 'button',
  variant = 'primary',
  className,
  children,
  disabled,
  loading,
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      className={cn(s.btn, variantToClassNameMap[variant], className)}
      disabled={disabled}
    >
      <div style={loading ? { visibility: 'hidden' } : {}}>{children}</div>

      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={25} />
        </div>
      ) : null}
    </button>
  );
}
